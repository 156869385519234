import { graphql, useStaticQuery } from 'gatsby'

export default () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          email
          links {
            advertising
            trottingProducts
          }
        }
      }
    }
  `)

  return site.siteMetadata
}
