import React from 'react' //, { useState } from 'react'
//import { HamburgerButton, Menu } from '@accodeing/gatsby-theme-heimr'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default () => {
  //const [menuOpen, setMenuOpened] = useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        logotype: file(relativePath: { eq: "logotype.png" }) {
          childImageSharp {
            fluid(maxWidth: 268, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <oma-grid-row
      contentAlignedWithGrid
      fullWidth
      class='row row--with-theme-color'
    >
      <header className='header'>
        <Link to='/'>
          <Img
            className='header__logotype'
            fluid={data.logotype.childImageSharp.fluid}
          />
        </Link>
        {/*
        <HamburgerButton
          open={menuOpen}
          onOpen={() => setMenuOpened(true)}
          onClose={() => setMenuOpened(false)}
        />
        <Menu open={menuOpen} />
        */}
      </header>
    </oma-grid-row>
  )
}
