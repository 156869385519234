import React from 'react'
import { CreatedBy } from '@accodeing/gatsby-theme-heimr'

import SiteMetadata from 'queries/SiteMetadata'

export default () => {
  const { email, phone } = SiteMetadata()

  return (
    <oma-grid-row
      contentAlignedWithGrid
      fullWidth
      class='row row--with-theme-color row--with-top-margin'
    >
      <footer className='footer'>
        <h2 className='footer__heading'>Kontakt</h2>
        <p>
          Magnus Bäck
          <br />
          <oma-link class='footer__link' to={phone} />
          <br />
          <oma-link class='footer__link' to={email} />
        </p>
        <CreatedBy />
      </footer>
    </oma-grid-row>
  )
}
